<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// Swiper.js
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

// vue components
import gotop from './components/Gotop.vue';

export default {
    inject: ["mq"],

    components: {
        Swiper,
        SwiperSlide,
        gotop,
    },

    data() {
        return {
            hash: location.hash, //URLハッシュ
            headerH: 0, //ヘッダーの高さ
            gnav: {
                isActive: false //グロナビ開閉
            },
            contact: {
                isActive: false //お問い合わせフォーム開閉
            },
            // スライダー設定
            swiper: {
                modules: [Pagination, Autoplay],
                options: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    loop: true,
                },
            }
        }
    },

    computed: {
        // 画面幅がタブレット以上、もしくは状態がactiveならグロナビを表示
        showGnav() {
            if (this.mq.mdMinus && !this.gnav.isActive) {
                return false;
            } else {
                return true;
            }
        },

        // フォーム　確認/完了画面かどうか
        isConfirm() {
            if (this.hash === '#wpcf7-f11-o1') return true;
        },

        // お問い合わせフォームを表示する条件
        showContact() {
            if (this.contact.isActive || this.isConfirm) return true;
        },
    },

    methods: {
        // wow.js初期化
        wowInit() {
            // SP/PC判定
            let offset;
            if (this.mq.mdMinus) {
                offset = 100; //SP
            } else {
                offset = 300; //PC
            }

            // 初期化
            const wow = new WOW({
                offset: offset,
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            if (!this.$refs.header) return;
            this.headerH = this.$refs.header.getBoundingClientRect().height;
        },

        // グロナビ開閉アニメーション
		beforeEnter(el) {
			el.style.height = '0';
		},
		enter(el) {
            el.style.height = `${window.innerHeight}px`;
		},
		beforeLeave(el) {
            el.style.height = `${window.innerHeight}px`;
		},
		leave(el) {
            el.style.height = '0';
		},

        // アンカーリンク
        anchorLink(hash) {
            if (!document.getElementById(hash)) return;
            this.$scrollTo(`#${hash}`, 500, {
                offset: -this.headerH, //ヘッダー分ずらす
                onStart: this.gnav.isActive = false //グロナビを閉じる
            });
        },

        // URLにハッシュがあればページ読み込み時にスクロール
        loadScroll() {
            if (this.isConfirm) return; //フォーム確認/完了画面では発火しない

            if (this.hash && this.hash.match(/^#.+$/)) {
                const target = this.hash.replace('#', '');
                window.onload = () => {
                    this.anchorLink(target);
                }
            }
        },

        // フォーム確認/完了画面のスクロール位置調整
        scrollInvaildContact() {
            const btn = document.querySelector('.js-form-btn'); //ボタン
            if (!this.isConfirm || !btn) return;

            const scroll = () => {
                this.anchorLink('contact');
            }
            window.addEventListener('load', scroll); //ロード時
            btn.addEventListener('click', scroll); //ボタンクリック時
        },

        // トップページ　aboutを抜けたらヘッダーの色を変える
        changeHeaderColor() {
            if (document.body.id != 'frontpage') return; //frontpage以外なら処理を終了

            let scroll; //スクロール量
            let offset; //発火する位置
            const trigger = document.getElementById('cafe'); //トリガーとなる要素
            const targetList = [ //classを追加/削除する要素
                this.$refs.header,
                this.$refs.menubar,
                this.$refs.gnav,
            ];

            window.addEventListener('scroll', () => {
                scroll = window.scrollY;
                offset = trigger.getBoundingClientRect().top + scroll;

                // スクロールがoffsetを超えたらfvクラスを削除
                if (scroll > offset) {
                    targetList.forEach((target) => {
                        target.classList.remove('is-fv');
                    });

                // スクロール量がoffset以内ならfvクラスを追加
                } else {
                    targetList.forEach((target) => {
                        target.classList.add('is-fv');
                    });
                }
            });
        },
    },

    mounted() {
        // wow.js
        this.wowInit();

        window.addEventListener('load', this.getHeaderH);
        window.addEventListener('resize', this.getHeaderH);
        this.loadScroll();
        this.scrollInvaildContact();
        this.changeHeaderColor();
    },

}
</script>