import { createApp } from "vue";
import { Vue3Mq } from "vue3-mq";
import App from "./App.vue";
const VueScrollTo = require('vue-scrollto');

import "../scss/style.scss";

//----------------------------------------------------------------------
//  読み込み
//----------------------------------------------------------------------
const app = createApp(App);
app.use(Vue3Mq, {
    breakpoints: {
        sm: 0,
        md: 768,
        lg: 1150,
        xl: 1320,
    }
});
app.use(VueScrollTo);

app.mount("#app");
