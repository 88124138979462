<template>
<div class="gotop" :class="{'is-show': isShow}">
	<a v-scroll-to="'body'"><div class="gotop__arrow"></div></a>
</div>
</template>

<script>
export default {
    data() {
        return {
            isShow: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 1000) {
                this.isShow = false;
            } else {
                this.isShow = true;
            }
        });
    },
}
</script>